//
// Page Content
// --------------------------------------------------

//
// Carousel
//

//To get carousel images and pages displayed on full screen size, all parent tags have to be set to height: 100%
html,
body,
section {
  height: 100%;
  margin-bottom: 20%; //prevent pages to overlap
}

.carousel,
.item,
.active {
  height: 100%;
}

.carousel-inner {
  height: 100%;
}

#carousel-container {
  width: 100%;
  height: 100%;
  padding: 0;
}

//Hide carousel text, show only title on smaller devices but reduce size
@media (max-width: @screen-md-min) {
  .carousel-caption {
    bottom: 10%; //move up the caption as we removed p tag
    h3 {
      font-size: @font-size-base;
      font-weight: bold;
    }

    p {
      //visibility: hidden !important;
      display: none !important;
    }
  }
}

//Use background image instead of slide images to allow scaling and background covering
//Download images from different sub-domains to allow simultaneous requests to server
#home {
  height: 100%;
  //We set image size according to layout breakpoint limits
  //xs: 500px x 500px
  //sm: 800 x 800
  //md: 1024 x 1024
  //lg: 1920 x 1200
  //For development only
  //use the followings statements for development

  #image1 {
    @media (max-width: @screen-xs-max) {
      background: transparent url("../img/xs/iStock_000020251374_500x500.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-sm-max) {
      background: transparent url("../img/sm/iStock_000020251374_800x800.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-md-max) {
      background: transparent url("../img/md/iStock_000020251374_1024x1024.jpg") center center no-repeat fixed;
    }
    @media (min-width: @screen-lg-min) {
      background: transparent url("../img/lg/iStock_000020251374_1920x1200.jpg") center center no-repeat fixed;
    }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  #image2 {
    @media (max-width: @screen-xs-max) {
      background: transparent url("../img/xs/iStock_000000559894_500x500.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-sm-max) {
      background: transparent url("../img/sm/iStock_000000559894_800x800.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-md-max) {
      background: transparent url("../img/md/iStock_000000559894_1024x1024.jpg") center center no-repeat fixed;
    }
    @media (min-width: @screen-lg-min) {
      background: transparent url("../img/lg/iStock_000000559894_1920x1200.jpg") center center no-repeat fixed;
    }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  #image3 {
    @media (max-width: @screen-xs-max) {
      background: transparent url("../img/xs/iStock_000017794310_500x500.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-sm-max) {
      background: transparent url("../img/sm/iStock_000017794310_800x800.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-md-max) {
      background: transparent url("../img/md/iStock_000017794310_1024x1024.jpg") center center no-repeat fixed;
    }
    @media (min-width: @screen-lg-min) {
      background: transparent url("../img/lg/iStock_000017794310_1920x1200.jpg") center center no-repeat fixed;
    }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  #image4 {
    @media (max-width: @screen-xs-max) {
      background: transparent url("../img/xs/iStock_000002938812_500x500.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-sm-max) {
      background: transparent url("../img/sm/iStock_000002938812_800x800.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-md-max) {
      background: transparent url("../img/md/iStock_000002938812_1024x1024.jpg") center center no-repeat fixed;
    }
    @media (min-width: @screen-lg-min) {
      background: transparent url("../img/lg/iStock_000002938812_1920x1200.jpg") center center no-repeat fixed;
    }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  #image5 {
    @media (max-width: @screen-xs-max) {
      background: transparent url("../img/xs/iStock_000016853066_500x500.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-sm-max) {
      background: transparent url("../img/sm/iStock_000016853066_800x800.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-md-max) {
      background: transparent url("../img/md/iStock_000016853066_1024x1024.jpg") center center no-repeat fixed;
    }
    @media (min-width: @screen-lg-min) {
      background: transparent url("../img/lg/iStock_000016853066_1920x1200.jpg") center center no-repeat fixed;
    }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  #image6 {
    @media (max-width: @screen-xs-max) {
      background: transparent url("../img/xs/6420187299_9110ff5c94_500x500.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-sm-max) {
      background: transparent url("../img/sm/6420187299_9110ff5c94_800x800.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-md-max) {
      background: transparent url("../img/md/6420187299_9110ff5c94_1024x1024.jpg") center center no-repeat fixed;
    }
    @media (min-width: @screen-lg-min) {
      background: transparent url("../img/lg/6420187299_9110ff5c94_1920x1200.jpg") center center no-repeat fixed;
    }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  #image7 {
    @media (max-width: @screen-xs-max) {
      background: transparent url("../img/xs/iStock_000025606418_500x500.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-sm-max) {
      background: transparent url("../img/sm/iStock_000025606418_800x800.jpg") center center no-repeat fixed;
    }
    @media (max-width: @screen-md-max) {
      background: transparent url("../img/md/iStock_000025606418_1024x1024.jpg") center center no-repeat fixed;
    }
    @media (min-width: @screen-lg-min) {
      background: transparent url("../img/lg/iStock_000025606418_1920x1200.jpg") center center no-repeat fixed;
    }
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }



  //End of Development statement

  //For deployment only
  //Use the preceding statements for development
  /*
  #image1 {
              @media (max-width: @screen-xs-max){
                  background: transparent url("//jp1.jphouix.fr/img/xs/iStock_000020251374_500x500.jpg") center center no-repeat fixed;
              }
              @media (max-width: @screen-sm-max){
                  background: transparent url("//jp1.jphouix.fr/img/sm/iStock_000020251374_800x800.jpg") center center no-repeat fixed;
              }
              @media (max-width: @screen-md-max){
                  background: transparent url("//jp1.jphouix.fr/img/md/iStock_000020251374_1024x1024.jpg") center center no-repeat fixed;
              }
              @media (min-width: @screen-lg-min){
                  background: transparent url("//jp1.jphouix.fr/img/lg/iStock_000020251374_1920x1200.jpg") center center no-repeat fixed;
              }
              -webkit-background-size: cover;
              -moz-background-size: cover;
              -o-background-size: cover;
              background-size: cover;
          }
          #image2 {
              @media (max-width: @screen-xs-max){
                  background: transparent url("//jp2.jphouix.fr/img/xs/iStock_000000559894_500x500.jpg") center center no-repeat fixed;
              }
              @media (max-width: @screen-sm-max){
                  background: transparent url("//jp2.jphouix.fr/img/sm/iStock_000000559894_800x800.jpg") center center no-repeat fixed;
              }
              @media (max-width: @screen-md-max){
                  background: transparent url("//jp2.jphouix.fr/img/md/iStock_000000559894_1024x1024.jpg") center center no-repeat fixed;
              }
              @media (min-width: @screen-lg-min){
                  background: transparent url("//jp2.jphouix.fr/img/lg/iStock_000000559894_1920x1200.jpg") center center no-repeat fixed;
              }
              -webkit-background-size: cover;
              -moz-background-size: cover;
              -o-background-size: cover;
              background-size: cover;
          }
          #image3 {
              @media (max-width: @screen-xs-max){
                  background: transparent url("//jp3.jphouix.fr/img/xs/iStock_000017794310_500x500.jpg") center center no-repeat fixed;
              }
              @media (max-width: @screen-sm-max){
                  background: transparent url("//jp3.jphouix.fr/img/sm/iStock_000017794310_800x800.jpg") center center no-repeat fixed;
              }
              @media (max-width: @screen-md-max){
                  background: transparent url("//jp3.jphouix.fr/img/md/iStock_000017794310_1024x1024.jpg") center center no-repeat fixed;
              }
              @media (min-width: @screen-lg-min){
                  background: transparent url("//jp3.jphouix.fr/img/lg/iStock_000017794310_1920x1200.jpg") center center no-repeat fixed;
              }
              -webkit-background-size: cover;
              -moz-background-size: cover;
              -o-background-size: cover;
              background-size: cover;
          }
          #image4 {
              @media (max-width: @screen-xs-max){
                  background: transparent url("//jp4.jphouix.fr/img/xs/iStock_000002938812_500x500.jpg") center center no-repeat fixed;
              }
              @media (max-width: @screen-sm-max){
                  background: transparent url("//jp4.jphouix.fr/img/sm/iStock_000002938812_800x800.jpg") center center no-repeat fixed;
              }
              @media (max-width: @screen-md-max){
                  background: transparent url("//jp4.jphouix.fr/img/md/iStock_000002938812_1024x1024.jpg") center center no-repeat fixed;
              }
              @media (min-width: @screen-lg-min){
                  background: transparent url("//jp4.jphouix.fr/img/lg/iStock_000002938812_1920x1200.jpg") center center no-repeat fixed;
              }
              -webkit-background-size: cover;
              -moz-background-size: cover;
              -o-background-size: cover;
              background-size: cover;
          }
          #image5 {
              @media (max-width: @screen-xs-max){
                  background: transparent url("//jp5.jphouix.fr/img/xs/iStock_000016853066_500x500.jpg") center center no-repeat fixed;
              }
              @media (max-width: @screen-sm-max){
                  background: transparent url("//jp5.jphouix.fr/img/sm/iStock_000016853066_800x800.jpg") center center no-repeat fixed;
              }
              @media (max-width: @screen-md-max){
                  background: transparent url("//jp5.jphouix.fr/img/md/iStock_000016853066_1024x1024.jpg") center center no-repeat fixed;
              }
              @media (min-width: @screen-lg-min){
                  background: transparent url("//jp5.jphouix.fr/img/lg/iStock_000016853066_1920x1200.jpg") center center no-repeat fixed;
              }
              -webkit-background-size: cover;
              -moz-background-size: cover;
              -o-background-size: cover;
              background-size: cover;
          }
          #image6 {
              @media (max-width: @screen-xs-max){
                  background: transparent url("//jp6.jphouix.fr/img/xs/iStock_000025606418_500x500.jpg") center center no-repeat fixed;
              }
              @media (max-width: @screen-sm-max){
                  background: transparent url("//jp6.jphouix.fr/img/sm/iStock_000025606418_800x800.jpg") center center no-repeat fixed;
              }
              @media (max-width: @screen-md-max){
                  background: transparent url("//jp6.jphouix.fr/img/md/iStock_000025606418_1024x1024.jpg") center center no-repeat fixed;
              }
              @media (min-width: @screen-lg-min){
                  background: transparent url("//jp6.jphouix.fr/img/lg/iStock_000025606418_1920x1200.jpg") center center no-repeat fixed;
              }
              -webkit-background-size: cover;
              -moz-background-size: cover;
              -o-background-size: cover;
              background-size: cover;
          }
  */
  //End of Deployment statement

}

section h1 {
  padding-top: 5%;
  padding-bottom: 2%;
}

.features-item {
  padding: 2%;
  text-align: center;

  .icon {
    font-size: floor(@font-size-h1 * 1.5);
  }
}

//
//Contacts
//

//Contact form
#contact-details {
  text-align: center;

  #linkedin {
    font-size: @font-size-h1;

    .icon {
      color: #007bb6; //Linkedin branding color
    }
  }
}

#contact-form {
  input {
    margin-bottom: 3%;
  }

  button {
    margin-top: 3%;
  }
}

@media (max-width: @screen-md-min) {
  section {
    height: auto; //do not limit section height of pages on smaller devices
  }
}