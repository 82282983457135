//
// Jumbotron
// --------------------------------------------------


.jumbotron {
  position: absolute;//added
  z-index: 900; //added
  padding: @jumbotron-padding;
  margin-bottom: @jumbotron-padding;
  color: @jumbotron-color;
  text-align: right; //added
  //background-color: @jumbotron-bg;
  
  //Make the jumbotron appears on right part of the screen
  @media (min-width: @grid-float-breakpoint){
        top: 20%; //edited was 20%
        left: 40%;
        width: 60%; //edited was: 100%
  }

  h1,
  .h1 {
    color: @jumbotron-heading-color;
    padding-bottom: 0;
    text-shadow: black 1px 1px 4px; //added
  }
  p {
    margin-bottom: (@jumbotron-padding / 2);
    font-size: @font-size-large; //edited was @jumbotron-font-size;
    font-weight: 200;
    font-style: italic;
    letter-spacing:0.05em;//added
    padding-bottom: 6%;//added
    text-shadow: black 1px 1px 4px; //added
  }

  .container & {
    border-radius: @border-radius-large; // Only round corners at higher resolutions if contained in a container
  }

  .container {
    max-width: 100%;
  }

  @media screen and (min-width: @screen-sm-min) {
    padding-top:    (@jumbotron-padding * 1.6);
    padding-bottom: (@jumbotron-padding * 1.6);

    .container & {
      padding-left:  (@jumbotron-padding * 2);
      padding-right: (@jumbotron-padding * 2);
    }

    h1,
    .h1 {
      font-size: (@font-size-base * 5);
      font-weight: bold;
    }
  }
}
